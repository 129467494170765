/* eslint-disable no-throw-literal */
import axios from "axios";
import { BASE_URL, BASE_URL_v2 } from "../../../crud/helpers/routes";
import { handleResponse } from "../../../crud/helpers/validate";
import notice from "../../../utils/notice";

export const LOGIN_URL = `${BASE_URL}/users/me`;
export const CREATE_OR_LOGIN_SSO_URL = `${BASE_URL}/users/login_or_create`;
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = `${BASE_URL}/users/request_password_reset`;
const SSO_LOGIN_URL = `${BASE_URL}/auth/check_if_sso`;
export const ME_URL = "api/me";
const BEGIN_EMAIL_OTP_FLOW_URL = `${BASE_URL_v2}/auth/begin/email`;
const TOKEN_BY_EMAIL_OTP_URL = `${BASE_URL_v2}/auth/token/email`;

const { REACT_APP_AUTH0_TOKEN_URL ,REACT_APP_AUDIENCE ,REACT_APP_CLIENT_ID ,REACT_APP_CLIENT_SECRET ,REACT_APP_URL_SSO_REDIRECT } = process.env

const getToken = async (email, password) => {
  const res = await axios.post(REACT_APP_AUTH0_TOKEN_URL, {
    username: email,
    password: password,
    grant_type: "password",
    audience: REACT_APP_AUDIENCE,
    client_id: REACT_APP_CLIENT_ID,
    client_secret: REACT_APP_CLIENT_SECRET,
  });

  if (res.status === 200 && res.data) return res.data;
};

export function login(email, password) {
  // let body = JSON.stringify({ username: email, password: password, grant_type: "password" })
  return getToken(email, password).then((res) => {
    if (res.access_token) {
      return fetch(LOGIN_URL, {
        headers: {
          Authorization: res.access_token,
        },
      })
        .then((response) => handleResponse(response))
        .then((json) => {
          if (
            (json.user.user_type && json.user.user_type === "staff") ||
            (json.user.user_type && json.user.user_type === "admin") ||
            json.user.is_admin
          ) {
            let user = {
              id: json.id ? json.id : json.user.id,
              first_name: json.user.first_name,
              user_type: json.user.user_type,
              last_name: json.user.last_name,
              is_admin: json.user.is_admin ? true : false,
              email: json.user.email,
              avatar: json.user.avatar,
              avatar_thumb: json.user.avatar_thumb,
              phone_number: json.user.phone_number,
              staff_member_admin: json.user.user_type === "staff" ?json.is_admin:false ,
            };

            if (json.school) user = { ...user, school: json.school };

            return {
              user: user,
              authToken: `Bearer ${res.access_token}`,
            };
          } else notice("Student's must use mobile application");
        })
        .catch((e) => {
          notice("Invalid username or password");
          console.error(e.errors);
        });
    }
  });
}

export function loginOrCreateUserBySSO(accessToken) {
  if (typeof accessToken == "undefined") return;

  return fetch(CREATE_OR_LOGIN_SSO_URL, {
    headers: {
      Authorization: accessToken,
    },
  })
    .then((strResponse) => {
      return strResponse.json().then((jsonResponse) => {
        if (jsonResponse.token) {
          return fetch(LOGIN_URL, {
            headers: {
              Authorization: jsonResponse.token,
            },
          })
            .then((response) => handleResponse(response))
            .then((json) => {
              if (
                (json.user.user_type && json.user.user_type === "staff") ||
                (json.user.user_type && json.user.user_type === "admin")  ||
                json.user.is_admin
              ) {
                let user = {
                  id: json.id ? json.id : json.user.id,
                  user_type: json.user.user_type,
                  first_name: json.user.first_name,
                  last_name: json.user.last_name,
                  is_admin: json.user.is_admin ? true : false,
                  email: json.user.email,
                  avatar: json.user.avatar,
                  avatar_thumb: json.user.avatar_thumb,
                  phone_number: json.user.phone_number,
                  staff_member_admin: json.user.user_type === "staff" ?json.is_admin:false ,
                };

                if (json.school) user = { ...user, school: json.school };

                return {
                  user: user,
                  authToken: `Bearer ${jsonResponse.token}`,
                };
              } else notice("Student's must use mobile application");
            })
            .catch((e) => {
              notice("Invalid username or password");
              console.error(e.errors);
            });
        }
      });
    })
    .catch((e) => {
      notice("Invalid username or SSO not enabled");
      console.error(e.errors);
    });
}

// TODO GET TOKEN FROM BAKCEND WITH AUTH TOKEN
export const ssoLogin = (email) => {
  return fetch(`${SSO_LOGIN_URL}?username=${email}&isEmail=1`)
    .then((res) => handleResponse(res))
    .then((json) => {
      if (json.is_sso_configured) {
        // Estas variables se pueden obtener desde las variables de entrono
        
        let connection_id = json.connection;

        // TODO SET BY ENV
        let redirect_uri = REACT_APP_URL_SSO_REDIRECT; // Esta la puse para hacer pruebas en local

        // Se puede usar cualquier otra funcion que direccióne al la url
        window.open(
          `https://itsthezone.us.auth0.com/authorize?response_type=token&client_id=61CJDg2BJs8m8TCfsOnYcfXtN5YQVBau&connection=${connection_id}&redirect_uri=${redirect_uri}&state=STATE&scope=openid%20profile%20email%20read:current_user%20offline_access`,
          "_self"
        );
      } else {
        throw { error: "SSO no configured" };
      }
    })
    .catch((e) => {
      console.log(e);
      throw e;
    });
};

export const sendBeginEmailOtp = (email) => {
  return axios.post(BEGIN_EMAIL_OTP_FLOW_URL, { username: email,isEmail:1 });
};

export const emailOtpLogin = (email, validationCode) => {
  return axios.post(TOKEN_BY_EMAIL_OTP_URL, { username: email, otp: validationCode }).then((res) => {
    const { data } = res;
    if (data?.access_token) {
      return fetch(LOGIN_URL, {
        headers: {
          Authorization: data?.access_token,
        },
      })
        .then((response) => handleResponse(response))
        .then((json) => {
          
          if (
            (json.user.user_type && json.user.user_type === "staff") || json.user.user_type == 'admin' ||
            json.user.is_admin
          ) {
            
            let user = {
              id: json.id ? json.id : json.user.id,
              user_type: json.user.user_type,
              first_name: json.user.first_name,
              last_name: json.user.last_name,
              is_admin: json.user.is_admin ? true : false,
              email: json.user.email,
              avatar: json.user.avatar,
              avatar_thumb: json.user.avatar_thumb,
              phone_number: json.user.phone_number,
              staff_member_admin: json.user.user_type === "staff" ?json.is_admin:false ,
            };
            
            if (json.school) user = { ...user, school: json.school };

            return {
              user: user,
              authToken: `Bearer ${data?.access_token}`,
            };
          } else notice("Student's must use mobile application");
        })
        .catch((e) => {
          notice("Invalid username or password");
          console.error(e.errors);
        });
    }
  });;
};

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
