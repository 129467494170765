/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
// import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { bindActionCreators } from "redux";

import * as auth from "../_redux/authRedux";
import * as schoolDUCK from "../../../../redux/ducks/school.duck";
import { ssoLogin, loginOrCreateUserBySSO, emailOtpLogin } from "../_redux/authCrud";
import { SCHOOL_URL } from "../../../crud/helpers/routes";
import { handleResponse } from "../../../crud/helpers/validate";
import { Alert } from '@material-ui/lab';
import { sendBeginEmailOtp } from '../_redux/authCrud';
var qs = require('qs');

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: "",
  validationCode: "",
};

function Login(props) {
  const { intl, authActions, setSelectedSchool } = props;
  const [loading, setLoading] = useState(false);
  const [wValidationCode, setWValidationCode] = useState(false)
  const [notSSO, setNotSSO] = useState(true)
  
  let hash = '?'+props.location.hash.substring(1, props.location.hash.length);
  const authParams = qs.parse(hash, { ignoreQueryPrefix: true })

  if(authParams.access_token){
    const userData = loginOrCreateUserBySSO(authParams.access_token);
    userData.then( (data) => {
      if (data.user.is_admin){
        setSchool(setSelectedSchool, data.authToken);
      }else if (data.user.school){
        setSelectedSchool(data.user.school);
      }
      authActions.login(data);
    })

  }

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    validationCode: Yup.string()
      .length(6, "Must be 6 digits")
      .required(
        wValidationCode &&
          intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_FIELD",
          })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const setSchool = (setterFunction, token) => {
    return fetch(`${SCHOOL_URL}/suggestions?q=`, {
      headers: {
        Authorization: token,
      },
    })
      .then((res) => handleResponse(res))
      .then((json) => setterFunction(json[0]))
      .catch((e) => console.error(e.errors));
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      
      enableLoading();
      setTimeout(
        () =>
          wValidationCode
            ? emailOtpLogin(values.email, values.validationCode)
                .then((data) => {
                  
                  disableLoading();
                  
                  if (data.user.is_admin)
                    setSchool(setSelectedSchool, data.authToken);
                  else if (data.user.school)
                    setSelectedSchool(data.user.school);
                  
                  authActions.login(data);
                })
                .catch(() => {
                  disableLoading();
                  setSubmitting(false);
                  setStatus(
                    intl.formatMessage({
                      id: "AUTH.VALIDATION.INVALID_LOGIN",
                    })
                  );
                })
            : ssoLogin(values.email)
                .then(() => {
                  setWValidationCode(false);
                  disableLoading();
                })
                .catch((e) => {
                  // Si el usuario no tiene sso, entonces se envía código de validación al correo y se muestra alerta
                  sendBeginEmailOtp(values.email)
                    .then(({ status }) => {
                      status === 200 && setWValidationCode(true);
                    }).finally(() => {
                      setNotSSO(true);                  
                      setSubmitting(false);
                      disableLoading();
                    })
                }),
        1000
      );
    },
  });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
        {/* <p className="text-muted font-weight-bold">
          Enter your username and password
        </p> */}
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      { notSSO ? null : <div className="d-flex justify-content-end">
        <a
          className="text-dark-50 text-hover-primary"
          onClick={() => (wValidationCode ? setWValidationCode(false) : setWValidationCode(true))}
        >
          {wValidationCode ? "Login with SSO" : "Login with password"}
        </a>
      </div>
      }

      <form
        noValidate={true}
        autoComplete="off"
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : (
          <>
            {/* <div className="mb-10 alert alert-custom alert-light-info alert-dismissible">
            <div className="alert-text ">
              Use account <strong>admin@demo.com</strong> and password{" "}
              <strong>demo</strong> to continue.
            </div>
          </div> */}
          </>
        )}

        <div className="fv-plugins-icon-container">
          {wValidationCode && <Alert className="mb-2" severity="info">We’ve sent you an access code to validate your account. Check your email to get your code</Alert>}
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        {wValidationCode && (
          <div className="fv-plugins-icon-container">
            <input
              placeholder="Validation Code"
              type="tel"
              className={`form-control form-control-solid h-auto py-5 px-6`}
              name="validationCode"
              {...formik.getFieldProps("validationCode")}
            />
            {formik.touched.validationCode && formik.errors.validationCode ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.validationCode}</div>
              </div>
            ) : null}
          </div>
        )}
        <div
          className={`form-group d-flex flex-wrap ${
            wValidationCode ? "justify-content-between" : "justify-content-end"
          } align-items-center`}
        >
          {/*wValidationCode && (
            <Link
              to="/auth/forgot-password"
              className="text-dark-50 text-hover-primary my-3 mr-2"
              id="kt_login_forgot"
            >
              <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
            </Link>
          )*/}
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3 ml-auto mr-0`}
          >
            <span>{wValidationCode ? "Validate" : "Continue"}</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setSelectedSchool: bindActionCreators(
    schoolDUCK.actions.setSelectedSchool,
    dispatch
  ),
  authActions: bindActionCreators(auth.actions, dispatch),
});

export default injectIntl(connect(null, mapDispatchToProps)(Login));
