import { Grid, makeStyles, Paper } from "@material-ui/core";
import React from "react";
import DashboardTitle from "./DashboardTitle";
import InfoIcon from "@material-ui/icons/Info";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#23263F",
    borderRadius: 8,
    padding: 8,
    width: ({ width }) => width,
    height: 'auto',
  },
  text: {
    width: '100%',
  },
  icon: {
    color: "#DFEAEE",
    backgroundColor: "#23263F",
    borderRadius: 8,
    width: 24,
    height: 24,
  },
}));

const DashboardChipActiveStudents = ({ width, active_students_count }) => {
  const count = active_students_count?.value ?? 0;
  const classes = useStyles({ width });
  return (
    <div>
      <Paper className={classes.container}>
        <Grid container direction="row" columns={12}>
          <Grid container item xs className="d-flex justify-content-between" >
            <DashboardTitle
              text={count}
              variant="dark.grey3"
              fontSize={24}
              fontWeight={400}
            />
            <InfoIcon className={classes.icon} />
          </Grid>
          <Grid container item className={classes.text} >
            <DashboardTitle
              lineHeight={17}
              text="Active Students"
              fontWeight={400}
              fontSize={14}
              variant="dark.grey3"
            />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

const mapStateToProps = (store) => ({
  active_students_count:
    store.dashboard.data.dashboard_data.active_students_count,
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardChipActiveStudents);
